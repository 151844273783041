import React, { useState } from "react";
import TabHeader from "./TabHeader";
import TabPanel from "./TabPanel";

const TabsUser = () => {
  const [tabActive, setTabActive] = useState(0);

  const changeTab = (event, newValue) => {
    setTabActive(newValue);
  };

  return (
    <React.Fragment>
      <TabHeader changeTab={changeTab} tabActive={tabActive} />
      <TabPanel value={tabActive} index={0} pickedUp={false} />
      <TabPanel value={tabActive} index={1} pickedUp={true} />
    </React.Fragment>
  );
};

export default TabsUser;
