import styled from "styled-components";
import { theme } from "../theme";

export const StyledNav = styled.nav`
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  width: 100%;
  background-color: gray;
  box-sizing: border-box;
  .top-container {
    display: flex;
    align-items: center;
    .logo-container {
      width: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .burger {
      display: none;
    }
  }
  & > ul {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    .logo-container {
      align-self: flex-start;
    }
    & > .item-menu {
      margin: 10px;
      color: ${theme.colors.white};
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &.active {
        color: black;
      }
    }
  }

  ${theme.breakpoints.onlyMobile} {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    .top-container {
      width: 100%;
      justify-content: space-between;
      .burger {
        display: flex;
      }
      .logo-container {
        width: 40px;
      }
    }

    & > ul {
      display: flex;
      height: auto;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      width: 100%;

      &.hide {
        display: none;
      }
      & > .item-menu {
        &:nth-child(odd) {
          background-color: darkgray;
        }
        margin: 0;
        padding: 10px;
        flex-basis: 100%;
      }
    }
  }
`;
