import React from "react"
import { IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';

const Burger = ({ onClick, className }) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <MenuIcon fontSize="large" htmlColor="white" />
    </IconButton>
  )
}

export default Burger
