import React from "react";
import "./styles.scss";

const ItemsDisplay = ({
  items: { title, ...items },
  callback = () => null,
  value = "",
  pre = "",
}) => {
  let renderItems = Object.keys(items);

  const splitted = value.replace(pre, "").split(".");
  const selected = renderItems.length === 1 ? renderItems[0] : splitted[0];
  const clickHander = ({ event, key }) => {
    event.stopPropagation();
    callback({ value: `${pre}${key}.`, valid: !items[key] });
  };

  const clickeableItem = (key) => (
    <div
      className={`items-display__item ${selected === key ? "selected" : ""}`}
      key={key}
      onClick={(event) => clickHander({ key, event })}
    >
      {key}
    </div>
  );
  renderItems = renderItems.map(clickeableItem);

  return (
    <>
      <div className="items-display__title">{title}</div>
      <div className="items-display">{renderItems}</div>
      {selected && items[selected] && (
        <ItemsDisplay
          items={items[selected]}
          callback={callback}
          value={value}
          pre={`${pre}${selected}.`}
        />
      )}
    </>
  );
};

export default ItemsDisplay;
