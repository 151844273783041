import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserInfo } from "../../utils/context";

const PrivateWrapper = ({ children, ...rest }) => {
  const user = useContext(UserInfo);
  const loggedOn = user.data;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedOn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateWrapper;
