import {
  userAPI,
  userTokenAPI,
  takeinAPI,
  pickupDeliver,
  department,
  getToken,
  endpoint,
  fakeResponses,
  newUsersAPI,
  assignOwnerAPI,
  assignRolesAPI,
  debtorsAPI,
  updateDebtorAPI,
} from "./constants";

const enableFakeAPI = true;

const callAPI = (url, config = {}) =>
  getToken()
    ? fetch(url, {
        ...config,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          ...config.headers,
        },
      })
    : fetch(url, {
        ...config,
        headers: {
          ...config.headers,
        },
      });

const fakeJSON = (fakeResponse) =>
  new Promise((resolve) => {
    resolve(fakeResponse);
  });

const validateResponse = (jsonResponse) =>
  new Promise((resolve, reject) => {
    if (jsonResponse.error)
      reject({
        message: `Algo salió mal, Contacte a soporte o intente de nuevo 
        (${JSON.stringify(jsonResponse.error)})
        `,
      });
    resolve(jsonResponse);
  });

const toJson = (raw) => {
  if (enableFakeAPI) {
    const path = fakeResponses[raw.url.split(`${endpoint}/`)[1]];
    if (path) return fakeJSON(path);
  }
  return raw.json().then(validateResponse);
};
export const getUser = () => callAPI(userAPI).then(toJson);
export const getDepartment = (id, pickedUd) =>
  callAPI(`${department}/${id}?pickedUp=${pickedUd}`).then(toJson);
export const getFlats = () => fakeJSON(fakeResponses.flats);
export const getDebtors = () => callAPI(debtorsAPI).then(toJson);
export const getNewUsers = () => callAPI(newUsersAPI).then(toJson);
export const setPicketDelivery = (id) =>
  callAPI(`${pickupDeliver}/${id}`, { method: "PUT" }).then(toJson);

export const setPackage = (packageInfo) =>
  callAPI(`${takeinAPI}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...packageInfo }),
  }).then(toJson);

export const assignOwner = (ownerInfo) =>
  callAPI(assignOwnerAPI, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...ownerInfo }),
  }).then(toJson);

export const assignRoles = (roleInfo) =>
  callAPI(`${assignRolesAPI}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...roleInfo }),
  });

  export const putToken = (token) =>
  callAPI(`${userTokenAPI}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  });

export const updateDebtor = (debtorInfo) =>
  callAPI(`${updateDebtorAPI}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...debtorInfo }),
  });
