import React from "react";
import "./styles.scss";

const NotFound = (props) => {
  return (
    <div className="not-found">
      <div>
        <h1>Algo Salió Mal</h1>
        <h4>La Pagina que buscas no existe, contacta a tu administrador</h4>
      </div>
    </div>
  );
};

export default NotFound;
