import { Work, Mail } from "@material-ui/icons";

export const tokenAlias = "cpmn";
export const endpoint = "https://build-management.herokuapp.com";
export const userAPI = `${endpoint}/user`;
export const userTokenAPI = `${endpoint}/user/token`;
export const newUsersAPI = `${endpoint}/admin/users`;
export const takeinAPI = `${endpoint}/admin/department/delivery`;
export const pickupDeliver = `${endpoint}/admin/department/deliver`
export const department = `${endpoint}/department`;
export const assignOwnerAPI = `${endpoint}/admin/department/assign`;
export const assignRolesAPI = `${endpoint}/admin/user/roles`;
export const debtorsAPI = `${endpoint}/admin/department/debtor`;
export const updateDebtorAPI = `${endpoint}/admin/department/debtor`;

export const USERTYPES = {
  RECEPCIONIST: "recepcionist",
  ADMIN: "admin",
  USER: "owner",
};

export const USERTYPES_LABELS = {
  [USERTYPES.RECEPCIONIST]: "Recepcion",
  [USERTYPES.ADMIN]: "Administracion",
  [USERTYPES.USER]: "Condomino",
};

export const routesByType = {
  [USERTYPES.RECEPCIONIST]: [
    { link: "/takein", label: "Recibir Paquetes" },
    { link: "/takeout", label: "Entregar Paquetes" },
  ],
  [USERTYPES.ADMIN]: [
    { link: "/linkuser", label: "Asignar Departamentos" },
    { link: "/assignroles", label: "Asignar Roles" },
    { link: "/debtors", label: "Deudores" },
    { link: "/takein", label: "Recibir Paquetes" },
    { link: "/takeout", label: "Entregar Paquetes" },
    { link: "/mymail", label: "Mi Correo" },
  ],
  [USERTYPES.USER]: [{ link: "/mymail", label: "Mi Correo" }],
};

export const getToken = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has(tokenAlias)) {
    localStorage.setItem(tokenAlias, searchParams.get(tokenAlias));
  }
  return localStorage.getItem(tokenAlias);
};
let floors = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17];
let flats = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"];
let tempFlats = { title: "Depto" };
flats.forEach((flat) => (tempFlats[flat] = null));
floors = floors.reduce((acc, floor) => ({ ...acc, [floor]: tempFlats }), {
  title: "Piso",
});

export const fakeResponses = {
  owner: {
    //path
    type: USERTYPES.ADMIN,
  },
  flats: {
    //path
    title: "Torre",
    D: {
      ...floors,
    },
  },
  debtors: ["D1701"],
  addPackage: {
    success: true,
  },
  newUsers: [
    {
      username: "miguel.velez@gmail.com",
      id: "1",
    },
    {
      username: "Facebook: miguel ",
      id: "2",
    },
    {
      username: "Telefono: 5511223344 ",
      id: "3",
    },
  ],
};

export const typePackages = {
  box: Work,
  envelope: Mail,
};


export const firebaseConfig = {
  apiKey: "AIzaSyCoGJPVbwQQve09zMvVe4liy5WpFDY6BI8",
  authDomain: "jmm-in-property.firebaseapp.com",
  projectId: "jmm-in-property",
  storageBucket: "jmm-in-property.appspot.com",
  messagingSenderId: "872186578627",
  appId: "1:872186578627:web:46ccb0662dd83336264476",
  measurementId: "G-DKPP1SZ1FJ"
};