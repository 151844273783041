import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { AssignmentReturn, LocalShipping } from "@material-ui/icons";

const TabHeader = ({ changeTab, tabActive }) => {

  const tabProps = (index) => {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  };

  return (
    <AppBar position="static" color="default">
      <Tabs
        style={{ backgroundColor: "gray" }}
        value={tabActive}
        onChange={changeTab}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="Tabs user"
      >
        <Tab label="En Recepción" icon={<AssignmentReturn />} {...tabProps(0)} />
        <Tab label="Recibidos" icon={<LocalShipping />} {...tabProps(1)} />
      </Tabs>
    </AppBar>
  );
}

export default TabHeader