import React, { useState, useEffect } from "react";
import "./styles.scss";
import TextField from "@material-ui/core/TextField";
import { getNewUsers } from "../../utils/calls";
import { providers } from "../../utils/providers";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

const UserList = ({ callback, selected }) => {
  const [newUsers, setNewUsers] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (newUsers.length === 0) getNewUsers().then((data) => setNewUsers(data));
  }, [newUsers]);

  const clickHandler = (event) => {
    const { id } = event.target.dataset;
    const selectedUser = newUsers.find((user) => user._id === id);
    if (selectedUser) {
      callback(selectedUser);
    }
  };

  return (
    <div className="user-list">
      <TextField
        label="Buscar Usuarios"
        className="search-input"
        color="primary"
        autoComplete="off"
        onChange={(event) => setSearch(event.target.value)}
      />
      <div onClick={clickHandler}>
        {newUsers
          .filter(({ name, email }) => {
            return name.includes(search) || email.includes(search);
          })
          .map(({ _id, provider, name, email, type }, index) => (
            <div
              key={_id}
              data-id={_id}
              className={`user ${
                selected && selected._id === _id ? "selected" : ""
              }`}
            >
              <img src={providers[provider].src} alt={provider} />
              <div>{`: ${email || name}`}</div>
              <AssignmentIndIcon />
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserList;
