import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import "./ContactUs.scss";

const ContactUs = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    description: "",
  });
  const [checked, setChecked] = useState(false)

  const onChange = ({ target }) => {
    setForm({ ...form, [target.id]: target.value });
  };

  const checkSubmit = () => {
    setChecked(true)
    return !!form.name
  };

  return (
    <div className="contact-us">
      <div className="contact-us__container">
        <h1>Comunicate con nosotros</h1>
        <form
          action="https://bigin.zoho.com/crm/WebToContactForm"
          name="CMIGNITEWebToContacts"
          method="POST"
          encType="multipart/form-data"
          onSubmit={checkSubmit}
          className="contact-us__form"
          acceptCharset="UTF-8"
        >
          <input
            type="text"
            style={{ display: "none" }}
            name="xnQsjsdp"
            readOnly
            value="a22ab4428cd3023c75ff6773dac7e5651eab25ca886cd207a8fc9fd2271c7274"
          />
          <input type="hidden" name="zc_gad" id="zc_gad" value="" readOnly />
          <input
            readOnly
            type="text"
            style={{ display: "none" }}
            name="xmIwtLD"
            value="f4addb8ddd1719d6d35dca8c25960c53ab094d022215864efd5da5d17534066e"
          />
          <input
            readOnly
            type="text"
            style={{ display: "none" }}
            name="actionType"
            value="Q29udGFjdHM="
          />
          <input
            readOnly
            type="text"
            style={{ display: "none" }}
            name="returnURL"
            value="https://ketesirvo.com"
          />

          <TextField
            id="name"
            name="Last Name"
            type="text"
            maxLength="80"
            defaultValue=""
            value={form.name}
            onChange={onChange}
            variant="outlined"
            className="form-field"
            label="Nombre"
            error={checked && !!form.name}
            helperText={checked ? "El nombre es Requerido" : ''}
          />

          <TextField
            id="email"
            name="Email"
            type="text"
            maxLength="100"
            defaultValue=""
            value={form.email}
            onChange={onChange}
            variant="outlined"
            className="form-field"
            label="Correo Electrónico"
          />

          <TextField
            id="description"
            name="Description"
            maxLength="32000"
            variant="outlined"
            defaultValue=""
            value={form.description}
            onChange={onChange}
            className="form-field"
            label="Mensaje"
            multiline
            rows={4}
          />

          <div className="contact-us__form__send">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              id="formsubmit"
            >
              Enviar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
