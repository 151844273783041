import React, { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Privacy from "./pages/Privacy/Privacy";
import Auth from "./pages/Auth";
import Modules from "./pages/Notifications";
import SectionSelector from "./pages/SectionSelector";
import PrivateWrapper from "./pages/PrivateWrapper";
import ContactUs from "./pages/ContactUs/ContactUs";
import { UserInfo } from "./utils/context";
import {
  getToken,
  USERTYPES,
  routesByType,
  firebaseConfig,
} from "./utils/constants";
import { getUser, getFlats, getDebtors } from "./utils/calls";
import "./App.scss";
import firebase from "firebase/app";




const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#2b2b2b",
    },
  },

  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "white",
          fontWeight: "bold",
        },
      },

      focused: {},
    },
  },
});
const baseLinks = [{ link: "/privacy", label: "Aviso de Privacidad" }];

const initFireBase = () => {
  firebase.initializeApp(firebaseConfig);
};

const App = () => {
  const [user, setUser] = useState({
    loading: true,
    token: getToken(),
  });

  const analyzeUserResponse = (data) => {
    initFireBase();
    setUser({
      loading: false,
      data,
    });
  };

  useEffect(() => {
    if (user.loading) {
      if (user.token) {
        getUser()
          .then(analyzeUserResponse)
          .catch((error) => {
            console.log({error})
            setUser({ loading: false });
          });
      } else {
        setUser({ loading: false });
      }
    } else if (!user.extraData) {
      const extraData = [];
      switch (user?.data?.type) {
        case USERTYPES.ADMIN:
        case USERTYPES.RECEPCIONIST:
          extraData.push(getFlats());
          extraData.push(getDebtors());
          break;
        default:
          break;
      }

      Promise.all(extraData).then(([flats, debtors]) => {
        setUser({ ...user, flats, debtors, extraData: true });
      });
    }
  }, [user]);

  const userLinks = user.data
    ? routesByType[user.data.type].map((section) => ({
        ...section,
        link: `/${user.data.type}${section.link}`,
      }))
    : [];

  return (
    <ThemeProvider theme={theme}>
      <UserInfo.Provider value={user}>
        <Router className="App">
          <NavBar navLinks={[...userLinks, ...baseLinks]} />
          <div className="content">
            {user.loading ? (
              <Loading />
            ) : (
              <Switch>
                <Route exact path="/" render={() => <Auth />} />
                <Route exact path="/contact-us" render={() => <ContactUs />} />
                <Route exact path="/privacy" render={() => <Privacy />} />
                <PrivateWrapper>
                  <Route
                    exact
                    path="/:type/:section"
                    render={(props) => <SectionSelector {...props} />}
                  />
                  <Route
                    exact
                    path="/:type"
                    render={(props) => <Modules {...props} />}
                  />
                </PrivateWrapper>
              </Switch>
            )}
          </div>
        </Router>
      </UserInfo.Provider>
    </ThemeProvider>
  );
};

export default App;
