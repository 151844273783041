import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StyledNav } from "./NavBar.styled";
import Burger from "./Burger";
import Logo from "../../assets/img/logo.svg";

const Nav = ({ navLinks = [] }) => {
  const [hide, setHide] = useState(true);
  return (
    <StyledNav onClick={() => setHide(true)}>
      <div className="top-container">
        <Link className="logo-container" to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <Burger
          className={"burger"}
          onClick={(event) => {
            event.stopPropagation();
            setHide(!hide);
          }}
        />
      </div>
      <ul className={hide ? "hide" : ""}>
        {navLinks.map(({ link, label }) => (
          <Link to={link} key={label} className="item-menu">
            {label}
          </Link>
        ))}
      </ul>
    </StyledNav>
  );
};

export default Nav;
