import React from "react";
import "./styles.scss";
import { couriers } from "../../utils/couriers";

const Couriers = ({ callback, selected }) => {
  return (
    <div className="couriers-container">
      {Object.entries(couriers).map(([, { src, label }]) => (
        <div
          onClick={() => callback(label)}
          className={selected === label ? "selected" : ""}
          key={label}
        >
          {src ? <img src={src} alt={label} /> : <label>{label}</label>}
        </div>
      ))}
    </div>
  );
};

export default Couriers;
