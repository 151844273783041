import React, { useState } from "react";
import "./styles.scss";
import { setPackage } from "../../utils/calls";
import UnitSelector from "../../components/UnitSelector";
import Couriers from "../../components/Couriers";
import { typePackages } from "../../utils/constants";
import { Notification } from "../../components/Notification/index";

const TakeIn = () => {
  const [vendor, setVendor] = useState("");
  const [type, setType] = useState("");
  const [showNotification, setNotification] = useState(false);

  const resetState = () => {
    setVendor("");
    setType("");
  };

  const onSentItem = () => {
    setNotification(true);
  };

  const sendRecord = ({ unit }) =>
    setPackage({
      departmentName: unit,
      delivery: {
        vendor,
        type,
      },
    });

  const showType = vendor;
  return (
    <div className="takein">
      <UnitSelector
        showFromParent={vendor && type}
        reset={resetState}
        service={sendRecord}
        onSentItem={onSentItem}
        innerElement={
          showType && (
            <div className="icons-container">
              {Object.entries(typePackages).map(([key, Item]) => {
                return (
                  <Item
                    className={type === key ? "selected" : ""}
                    onClick={() => {
                      setType(key);
                    }}
                  />
                );
              })}
            </div>
          )
        }
      >
        <Couriers callback={setVendor} selected={vendor} />
      </UnitSelector>
      <Notification
        showNotification={showNotification}
        message={"Se guardó correctamente"}
        onCloseNotification={() => {
          setNotification(false);
        }}
      />
    </div>
  );
};

export default TakeIn;
