import React, { useState } from "react";
import "./styles.scss";
import UnitSelector from "../../components/UnitSelector";
import { getDepartment, setPicketDelivery } from "../../utils/calls";
import TablePackage from "../../components/TablePackage/TablePackage";
import { Notification } from "../../components/Notification/index";

const TakeOut = () => {
  const [packages, setPackages] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showNotification, setNotification] = useState(false);

  const resetState = () => {
    setPackages([]);
  };

  const onSentItem = () => {
    setNotification(true);
  };

  const showPackages = ({ unit }) => setPicketDelivery(selectedItem._id);

  const onValidUnit = (item) => {
    getDepartment(item.value, false)
      .then((response) => {
        setPackages(response.deliveries);
        setSelectedItem(null);
      })
      .catch((erro) => {
        setPackages([]);
        setSelectedItem(null);
      });
  };

  return (
    <div className="takeout">
      <UnitSelector
        reset={resetState}
        service={showPackages}
        OnValidUnit={onValidUnit}
        onSentItem={onSentItem}
        showFromParent={!!selectedItem}
      >
        <div style={{ margin: "20px 0" }}>
          <TablePackage
            packages={packages}
            OnSelectedItem={(item) => {
              setSelectedItem(item);
            }}
          />
        </div>
      </UnitSelector>
      <Notification
        showNotification={showNotification}
        message={"Se guardó correctamente"}
        onCloseNotification={() => {
          setNotification(false);
        }}
      />
    </div>
  );
};

export default TakeOut;
