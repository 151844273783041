import GoogleLogo from "../assets/img/Google_Logo.svg";
import FacebookLogo from "../assets/img/Facebook_Logo.svg";
import SMSLogo from "../assets/img/SMS_Logo.svg";
import { endpoint } from "./constants";

const providers = {
  sms: {
    src: SMSLogo,
    backgroundColor: "#de5246",
    color: "white",
    specialLabel: "SMS",
  },
  google: { src: GoogleLogo, backgroundColor: "white" },
  facebook: {
    src: FacebookLogo,
    backgroundColor: "rgb(25, 119, 243)",
    color: "white",
  },
};

const providersNames = Object.keys(providers);

providersNames.forEach(
  (provider) =>
    (providers[provider] = {
      href: `${endpoint}/auth/${provider.toLocaleLowerCase()}`,
      label: `Inicia Sesión con ${
        providers[provider].specialLabel || provider[0].toUpperCase() + provider.slice(1)
      }`,
      ...providers[provider],
    })
);

export { providers, providersNames };
