import React, { useContext, useState, useEffect } from "react";
import TablePackage from "../TablePackage/TablePackage";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { UserInfo } from "../../utils/context";
import { getDepartment } from "../../utils/calls";

const TabPanel = (props) => {
  const [departments, setDepartments] = useState([]);
  const user = useContext(UserInfo);
  const { value, index, pickedUp, ...other } = props;

  useEffect(() => {
    const data = Promise.all(
      user.data.departments.map((item) => {
        return new Promise((resolve) => {
          getDepartment(item.name, pickedUp).then((response) => {
            resolve(response);
          });
        });
      })
    );

    data.then(response => {
      setDepartments([...response])
    })
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {departments.map((item, key) => (
            <Accordion defaultExpanded={key === 0}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${key}a-content`}
                id={`panel${key}-header`}
              >
                Departamento: {item.name.replace(/\./g, "")}
              </AccordionSummary>
              <AccordionDetails>
                <TablePackage packages={item.deliveries} key={key} />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
