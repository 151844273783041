import AmazonLogo from "../assets/img/Amazon_Logo.svg";
import MLLogo from "../assets/img/ML_Logo.png";
import DHLLogo from "../assets/img/DHL_Logo.svg";
import EstafetaLogo from "../assets/img/Estafeta_Logo.svg";
import FedExLogo from "../assets/img/FedEx_Logo.svg";

const couriers = {
  Amazon: { src: AmazonLogo },
  MercadoLibre: { src: MLLogo },
  DHL: {
    src: DHLLogo,
  },
  Estafeta: {
    src: EstafetaLogo,
  },
  Fedex: {
    src: FedExLogo,
  },
  Otro: {
    label: "Otro",
  },
};

const courierNames = Object.keys(couriers);

courierNames.forEach(
  (courier) =>
    (couriers[courier] = {
      label: `${courier}`,
      ...couriers[courier],
    })
);

export { couriers, courierNames };
