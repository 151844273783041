import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TabsUser from "../../components/TabsUser/TabsUser";
import "./styles.scss";

export default function MyMail() {

  return (
    <div className="my-mail">
      <Box p={3}>
        <Typography component="h1" color="primary">
          Mis Paquetes
        </Typography>
      </Box>
      <TabsUser />
    </div>
  );
}
