import { css } from 'styled-components'

export const screenWidth = {
  mobile: '576px',
  tablet: '768px' /* sm */,
  laptop: '992px' /* md */,
  desktop: '1224px' /* lg */,
}
// Mobile first tooling
/*
  Usage on styled component=:
  // This breakpoint  apply since tablet to entire desktop
  ${theme.breakpoint.tablet`
    flex-direction: row;    
  `}
*/
const breakpoint = Object.keys(screenWidth).reduce((acc, key) => {
  acc[key] = (...args) => css`
    @media screen and (min-width: ${screenWidth[key]}) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const theme = {
  colors: {
    dark: "#2D2D2D",
    red: "#D4145A",
    white: "#FFFFFF",
    gray: "#D4145A",
    textWhite: "#F1F1F1",
    textRed: "#D4145A",
    blue: "#0E597E",
    dateColor: "#02A99D",
    letterRed: "#C4C4C4",
  },
  breakpoint, // Mobile first tooling
  breakpoints: {
    onlyMobile: "@media only screen and (max-width: 574px)",
    tablet: "@media only screen and (min-width: 575px)",
    desktop: "@media only screen and (min-width: 960px)",
  },
  fonts: {
    allSite: "BebasNeue-Regular",
  },
  lookAndFeel: {
    radius: '30px',
    gradientItem: 'linear-gradient(182.29deg, #FBB03B 5.5%, #D4145A 98.09%)'
  }
}
