import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import "./Auth.scss";
import ButtonProvider from "../../components/ButtonProvider";
import { providersNames } from "../../utils/providers";
import { UserInfo } from "../../utils/context";

const Auth = () => {
  const user = useContext(UserInfo);

  if (user?.data?.type) {
    if (user.data.sections && user.data.sections[0]?.link)
      return (
        <Redirect to={`/${user.data.type}${user.data.sections[0].link}`} />
      );
    return <Redirect to={`/${user.data.type}`} />;
  }

  return (
    <div className="auth">
      <div className="form">
        <h4>¿Como quieres iniciar sesion?</h4>
        <div className="buttons-container">
          {providersNames.map((provider) => (
            <ButtonProvider provider={provider} key={provider}></ButtonProvider>
          ))}
        </div>
        <div className="disclaimer">
          *El proveedor sólo nos proporcionara tu nombre y/o correo. Tambien
          puedes usar la autenticacion sms, si lo prefieres
        </div>
      </div>
    </div>
  );
};

export default Auth;
