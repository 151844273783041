import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { UserInfo } from "../../utils/context";
import { USERTYPES } from "../../utils/constants";
import { getNewUsers, assignOwner } from "../../utils/calls";
import UnitSelector from "../../components/UnitSelector";
import Loading from "../../components/Loading";
import NotFound from "../NotFound";

import UserList from "../../components/UserList";

const LinkUser = () => {
  const user = useContext(UserInfo);
  const [newUsers, setNewUsers] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    if (!newUsers) getNewUsers().then((data) => setNewUsers(data));
  }, [newUsers]);

  const resetState = () => {
    setSearch("");
  };

  const sendRecord = ({ unit }) =>
    assignOwner({
      departmentName: unit,
      ownerId: selectedUser._id,
    });

  const clickHandler = (event) => {
    const { userid } = event.target.dataset;
    if (userid) {
      setSelectedUser(selectedUser === userid ? "" : userid);
    }
  };
  if (user.data.type !== USERTYPES.ADMIN) return <NotFound />;
  return newUsers ? (
    <div className="link-users" onClick={clickHandler}>
      <UserList selected={selectedUser} callback={setSelectedUser}></UserList>
      <div>
        <UnitSelector
          showFromParent={selectedUser !== ""}
          reset={resetState}
          service={sendRecord}
        />
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default LinkUser;
