import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
} from "@material-ui/core";
import { typePackages } from "../../utils/constants";
import { couriers } from "../../utils/couriers";
import "./styles.scss";

const TablePackage = ({ packages = [], OnSelectedItem }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();

  const selectItem = (index) => {
    setSelectedItem(index);
    if (OnSelectedItem) {
      OnSelectedItem(packages[index]);
    }
  };

  useEffect(() => {
    setSelectedItem(null);
  }, [packages]);



  return (
    <TableContainer component={Paper} color="secondary">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Deliver</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Días</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packages.length > 0 ? (
            packages.map(({ vendor, type, createdAt }, index) => {
              const Tag = typePackages[type] || React.Fragment;

              return (
                <TableRow
                  className={`${selectedItem === index ? "active" : ""}`}
                  key={`${index}-${vendor}`}
                  onClick={() => {
                    selectItem(index);
                  }}
                >
                  <TableCell>{couriers[vendor] && couriers[vendor].src?<img className="vendor" src={couriers[vendor].src} alt={vendor} /> :vendor}</TableCell>
                  <TableCell>
                    <Tag />
                  </TableCell>
                  <TableCell>
                    {Math.round(
                      Math.abs((new Date(createdAt) - today) / oneDay)
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3}>No hay Paquetes disponibles</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablePackage;
