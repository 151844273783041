import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { updateDebtor } from "../../utils/calls";
import { UserInfo } from "../../utils/context";
import UnitSelector from "../../components/UnitSelector";
import Chip from "@material-ui/core/Chip";

const Debtors = () => {
  const user = useContext(UserInfo);

  const [sessionDebtors, setSessionDebtors] = useState([]);

  useEffect(() => {
    if (user.debtors) {
      setSessionDebtors([...user.debtors]);
    }
  }, [user]);

  const addDebtor = ({ unit }) =>
    updateDebtor({
      departmentName: unit,
      active: true,
    }).then(setSessionDebtors([...sessionDebtors, unit]));

  const removeDebtor = ({ unit }) =>
    updateDebtor({
      departmentName: unit,
      active: false,
    }).then(() => {
      setSessionDebtors(sessionDebtors.filter((debtor) => debtor !== unit));
    });
  if (!user.debtors) return null;

  return (
    <div className="debtors">
      <div className="list">
        {sessionDebtors.map((debtor) => (
          <Chip
            key={debtor}
            label={debtor}
            onDelete={() => removeDebtor({ unit: debtor })}
            color="primary"
          />
        ))}
      </div>
      <UnitSelector service={addDebtor}></UnitSelector>
    </div>
  );
};

export default Debtors;
