import React, { useContext, useState } from "react";
import "./styles.scss";
import { UserInfo } from "../../utils/context";
import ItemsDisplay from "../ItemsDisplay";
import Button from "@material-ui/core/Button";

const UnitSelector = ({
  children,
  reset,
  service,
  labelButton = "Acceptar",
  showFromParent = true,
  innerElement = null,
  OnValidUnit,
  onSentItem
}) => {
  const user = useContext(UserInfo);
  const [unit, setUnit] = useState({ value: "", valid: false });
  const [error, setError] = useState("");
  const [executing, setExecuting] = useState(false);

  const resetState = () => {
    setError("");
    setExecuting(false);
    reset && reset();
  };

  const sendRecord = () => {
    setExecuting(true);
    service &&
      service({
        unit: unit.value,
      })
        .then(() => {
          resetState();
          setUnit({ value: "", valid: false });
          onSentItem && onSentItem()
          reset && reset();
        })
        .catch((error) =>
          setError(error.message ? error.message : JSON.stringify(error))
        );
  };

  const setUnitReset = (item) => {
    setUnit(item)

    if (item.valid && OnValidUnit) {
      OnValidUnit(item)
    }

    resetState()
  }

  if (!user.flats || !user.debtors) return null;
  const displayUnit = unit.value.replace(/\./g, "");
  const showDebtAlert = unit.valid && user.debtors.includes(unit.value);
  const showUnit = unit.valid && !showDebtAlert;

  if (user.flats && user.debtors) {
    return (
      <div className="unit-selector">
        <ItemsDisplay
          items={user.flats}
          callback={setUnitReset}
          value={unit.value}
        />
        {showUnit && children}

        <div className="buttons-container">
          <span className={`unit ${showUnit ? "valid" : ""}`}>
            {displayUnit}
          </span>
          {innerElement}
          {showUnit && showFromParent && (
            <Button
              onClick={sendRecord}
              disabled={executing}
              variant="contained"
            >
              {labelButton}
            </Button>
          )}
          {showDebtAlert && (
            <Button disabled={true} variant="contained" className="debtor">
              {"Deudor"}
            </Button>
          )}
        </div>
        {(showDebtAlert || error) && (
          <div className="alert">
            {error || "EXISTEN ADEUDOS, NO SE DEBE BRINDAR EL SERVICIO"}
          </div>
        )}
      </div>
    );
  }
};

export default UnitSelector;
