import React from "react";
import "./ButtonProvider.scss";
import { providers } from "../../utils/providers";

const ButtonProvider = ({ provider }) => {
  const { src, href, label, backgroundColor, color } = providers[provider];

  return (
    <a
      href={href}
      className="ButtonProvider"
      style={{ backgroundColor, color }}

    >
      <img src={src} alt={label} />
      <span>{label}</span>
    </a>
  );
};

export default ButtonProvider;
