import React, { useContext, useState } from "react";
import "./styles.scss";
import { UserInfo } from "../../utils/context";
import { USERTYPES, USERTYPES_LABELS } from "../../utils/constants";
import { assignRoles } from "../../utils/calls";
import NotFound from "../NotFound";
import Button from "@material-ui/core/Button";
import UserList from "../../components/UserList"

const LinkUser = () => {
  const user = useContext(UserInfo)
  const [selectedUser, setSelectedUser] = useState(null);
  const [newRoles, setNewRoles] = useState({});

  const clickTypeHandler = (evt) => {
    const { usertype } = evt.target.dataset;
    if (!usertype) return;
    const { _id } = selectedUser;
    assignRoles({ userId: _id, roleId: usertype }).then(() => {
      setNewRoles({...newRoles,[_id]:usertype})
    });
  };
  if (user.data.type !== USERTYPES.ADMIN) return <NotFound />;
  const userType=selectedUser? newRoles[selectedUser._id] || selectedUser.type:false
  return (
    <div className="assign-roles"> 
    <UserList selected={selectedUser} callback={setSelectedUser} />
      <div className="user-types" onClick={clickTypeHandler}>
        {Object.keys(USERTYPES_LABELS).map((key) => (
          <Button
            data-usertype={key}
            className={`type ${
               key === userType? "current" : ""
            }`}
          >
            {USERTYPES_LABELS[key]}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default LinkUser;
