import React from "react";
import "./styles.scss";
import TakeIn from "../TakeIn";
import TakeOut from "../TakeOut";
import LinkUser from "../LinkUser";
import MyMail from "../MyMail/MyMail";
import AssignRoles from "../AssignRoles";
import Debtors from "../Debtors";
import NotFound from "../NotFound";

const SectionSelector = (props) => {
  const {
    match: {
      params: { section },
    },
  } = props;

  switch (section) {
    case "takein":
      return <TakeIn />;
    case "takeout":
      return <TakeOut />;
    case "linkuser":
      return <LinkUser />;
    case "mymail":
      return <MyMail />;
    case "assignroles":
      return <AssignRoles />;
    case "debtors":
      return <Debtors />;
    default:
      return <NotFound />;
  }
};

export default SectionSelector;
