import React from "react";

const html = `<div id="contenido">
<!-- File: /app/View/Policies/view.ctp -->



<!--INICIO: MODELO A-->

<!--Modelo A. Aviso de privacidad integral<br>

<h1>AVISO DE PRIVACIDAD</h1>
-->

<table border="0" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 20px;width: 100%;margin-left:10%;padding: 0;margin: 0;border: none;border-collapse:collapse;" align="center">
<tbody><tr>
    <td style="text-align: center;font-family: Arial, Helvetica, sans-serif;font-weight: bold;font-size: 20px;">
        AVISO DE PRIVACIDAD
    </td>
</tr>
<tr><td>&nbsp;</td></tr>
</tbody></table>
<table border="0" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;width: 100%;padding: 0;border: none;border-collapse:collapse;">
<tbody><tr>
<td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
JMM Solutions S.A. de C.V    , mejor conocido como JMM Solutions    , con domicilio en  calle Fresas 179, colonia Tlacoquemecatl del Valle, ciudad Ciudad de Mexico, municipio o delegación Benito Juarez, c.p. 03200, en la entidad de Ciudad de Mexico, país Mexico    , y portal de internet contacto@jmmsquaresolutions.com    , es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
</td></tr>
<tr><td>&nbsp;</td></tr>

<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: bold;font-size: 13px;">
¿Para qué fines utilizaremos sus datos personales?
</td></tr>
<tr><td>&nbsp;</td></tr>
<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita: 
<!--[Listado de finalidades en las que el responsable haya indicado que son necesarias para la relación jurídica con el titular en las preguntas 1 y 2 de la Sección IV].--> 
</td></tr>

<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    <ul><li>Autenticación</li></ul></td></tr>

<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    <ul></ul></td></tr>
<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
</td></tr>

<!--OK-->

 

<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
</td></tr>

<!-- FIN DE SECCION -->
<tr><td>&nbsp;</td></tr>
<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: bold;font-size: 13px;">
 ¿Qué datos personales utilizaremos para estos fines?
</td></tr>
<tr><td>&nbsp;</td></tr>
<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
<!--[listado de datos personales o sus categorías, según lo indicado por el responsable en las preguntas 2 y 3 de la Sección V].-->

<!--seccion 5, pregunta 2-->
    <br>
    <ul><li>Nombre</li>
    <li>Correo electrónico</li>
    y/o		
    <li>Teléfono celular</li>
    

    

<!--seccion 5, pregunta 3-->
        

    </ul></td></tr>



</tbody></table><table align="center" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;padding: 0;width: 100%;border-collapse:collapse;" border="0">
        <tbody><tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: bold;font-size: 13px;">
<br>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?
</td></tr>  <tr><td>&nbsp;</td></tr>
<tr>
 <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). 
Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta 
(Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente 
(Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</td></tr>
<tr><td>&nbsp;</td></tr>
<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio: <br><br>
 
<!--[Descripción del medio que haya indicado el responsable en la pregunta 1 de la Sección VII].-->

enviando un correo electrónico a contacto@jmsquaresolutions.com   </td></tr>
</tbody></table>


    <!--[El siguiente texto aparecerá en caso de que el responsable haya elegido la primera opción de la pregunta 2 de la Sección VII]:-->
    <table align="left" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;padding: 0;width: 100%;border-collapse:collapse;" border="0">
            <tbody><tr><td>&nbsp;</td></tr>
        <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:<br><br> </td></tr>
    <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    <!--[Información que haya proporcionado el responsable en la pregunta 3 de la Sección VII]-->
    a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?<br>
    Identificación oficial vigente</td></tr><tr><td>&nbsp;</td></tr>
    <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    b) ¿Qué información y/o documentación deberá contener la solicitud?<br>
    Copia de identificación oficial y descripción de la solicitud</td></tr><tr><td>&nbsp;</td></tr>
    <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    c) ¿En cuántos días le daremos respuesta a su solicitud?<br>
    20 a 30 días hábiles</td></tr><tr><td>&nbsp;</td></tr>
    <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?<br>
    Al correcto electrónico de donde se origine la petición</td></tr><tr><td>&nbsp;</td></tr>
    <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    e) ¿En qué  medios se pueden reproducir los datos personales que, en su caso, solicite?<br> 
    Medios electrónicos</td></tr><tr><td>&nbsp;</td></tr>
    <tr>
    
            
    
  
</tr></tbody></table>

<table align="left" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;padding: 0;width: 100%;border-collapse:collapse;" border="0">
        <tbody><tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
<br>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, 
son los siguientes: <br></td></tr><tr><td>&nbsp;</td></tr>
<!--[Datos proporcionados en la pregunta 5 de la Sección VII]-->
<tr>
<td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    
a) Nombre de la persona o departamento de datos personales: Atencion al cliente<br>    
<!--b) Domicilio:-->
    b) Domicilio:  calle Fresas 179, colonia Del Valle, ciudad Ciudad de Mexico, municipio o delegación Benito Juarez, c.p. 03200, en la entidad de Ciudad de Mexico, país Mexico<br>

c) Correo electrónico: contacto@jmsquaresolutions.com<br>
<br>
<text style="font-weight:bold;">Usted puede revocar su consentimiento para el uso de sus datos personales</text><br><br>
Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, 
es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, 
ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. 
Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el 
servicio que nos solicitó, o la conclusión de su relación con nosotros.<br><br>

Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio: <br><br>
<!--[Describir los medios indicados en la pregunta 1 de la Sección VIII].--> 

correo electrónico    
<!--[El siguiente texto aparecerá en caso de que el responsable haya elegido la primera opción de la pregunta 2 de la Sección VIII]:-->
</td></tr>
      <tr>
      <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    <br>Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente: <br><br>
    <!--[Información que haya proporcionado el responsable en la pregunta 3 de la Sección VII]-->
    
    a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?<br>
    Identificación oficial vigente<br><br>
    b) ¿Qué información y/o documentación deberá contener la solicitud?<br>
    Copia de identificación oficial y descripción de la solicitud<br><br>
    c) ¿En cuántos días le daremos respuesta a su solicitud?<br>
    20 a 30 días hábiles<br><br>
    d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?<br>
    Al correcto electrónico de donde se origine la petición<br><br>
            </td></tr>
    
    

</tbody></table>

    <table align="center" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;padding: 0;width: 100%;border-collapse:collapse;" border="0">
<tbody><tr><td>&nbsp;</td></tr>
        <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: bold;font-size: 13px;">
    ¿Cómo puede limitar el uso o divulgación de su información personal?
    </td></tr><tr><td>&nbsp;</td></tr>
    <!--[Esta sección sólo aparecerá en el caso de que el responsable haya respondido que sí en la pregunta 1 de la Sección IX, o bien, haya señalado que le aplica algunos de los registros de la pregunta 3 de esa sección]-->
    <tr>
            <td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
    Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:<br><br>
    
    Contacto via correo electrónico<br><br>        
    
            </td></tr><tr><td>&nbsp;</td></tr></tbody></table>
    <!--[Información proporcionada en la pregunta 5 de la Sección IX]-->
    
    
<!-- SECCION 10 -->



<!-- SECCON 11-->

<table align="left" style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;padding: 0;width: 100%;border-collapse:collapse;" border="0">
<tbody><tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: bold;font-size: 13px;width: 100%">
    <br>¿Cómo puede conocer los cambios en este aviso de privacidad?</td></tr><tr><td>&nbsp;</td></tr>
<tr><td style="text-align: left;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras 
propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, 
o por otras causas.<br>

<br>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: 
<!--[descripción del medio informado en la pregunta 1 de la Sección XI].-->    
correo electrónico.

<br><br>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente: <br><br>portal de internet    
<!--[descripción del procedimiento que se haya descrito en la pregunta 3 de la Sección XI].-->

</td></tr>
</tbody></table>

<!-- SECCON 13-->



        
        
<table width="100%">
<tbody><tr><td>&nbsp;</td></tr>
<tr>
<td style="text-align: right;font-family: Arial, Helvetica, sans-serif;font-weight: lighter;font-size: 13px;">
  Última actualización:   05/03/2021		</td>
</tr>
</tbody></table>

<!--<p align="right">
Última actualización 20/may/2013.
</p>-->

<!--FIN: MODELO A-->



      </div>
`;

const Privacy = () => {
  return (
    <div
      className="privacy page"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      style={{ padding: "50px", color: "white" }}
    />
  );
};

export default Privacy;
