import React, { useState } from "react";
import "./styles.scss";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase/app";
import "firebase/messaging";
import { putToken } from "../../utils/calls";

const enableNotifications = (callback) => {
  const messaging = firebase.messaging();
  messaging
    .getToken({
      vapidKey:
        "BJtzh7ZuZ_OKn0a_YvSFlCidh75_TipD3EYpszyHIVY8A40bMHrAxl_7og5oaFaRbgAVsNRIddr-Yu9xChxyxkM",
    })
    .then((currentToken) => {
      if (currentToken) {
        putToken(currentToken).then(() => {
          callback && callback(currentToken);
        });
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
const Notifications = (props) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <div className="notifications">
      <div className="interactive">
        <Typography color="primary">
          {
            token?"Tus notificaciones estan activadas":"Queremos informarte de la llegada de todos tus paquetes.Si estas de acuerdo activa las notificaciones para esta aplicacion en el siguiente boton.(El navegador te pedira una confirmacion)"
          }
        </Typography>

        {!loading && !token && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setLoading(true);
              enableNotifications((token) => {
                setLoading(false);
                setToken(token);
              });
            }}
          >
            {loading ? "..." : "Activar notificaciones"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Notifications;
