import React from "react";
import "./Loading.scss";

const Loading = () => (
  <div className="loader-container">
    <div className="loader" />
  </div>
);

export default Loading;
